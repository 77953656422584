<template>
<div class="page" v-if="load">
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div class="page" v-if="!load">
    <reservation-header
      :status="resData.id ? 'Saved' : 'New'"
      :title="'Cesija'"
      @save="saveData"
      @delete="deleteData"
      @saveAndLock="saveAndLock"
      @newNormal="createNormal"
      @newAdvance="createAdvance"
    ></reservation-header>
    <data-form2
      :key="resData['id'] + nextKey"
      :data="resData"
      :formFields="formFields"
      @changed="inputChanged"
      :readonly="true"
      minheight="1px"
      :nooverflow="true"
      @findFromRacChassis="findFromRacChassis"
      @findFromRac="findFromRac"
    />
    <form-futher :key="resData['id'] + 'foother'" v-if="resData" 
    :createdBy="resData['createdBy']"
    :created="resData['createdAt']"
    :updatedBy="resData['updatedBy']"
    :updated="resData['updatedAt']"
    />
  </div>
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import FormFuther from "../users/FormFuther.vue";
import CessionsDataService from "../../services/cessions.service";
import ReservationHeader from "../reservation/header/ReservationHeader";
import ExchangeRatesDataService from "../../services/exchange-rates.service";
import utils from "../../utils/common";

export default {
  name: "cession-form",
  props: [],
  components: {
    DataForm2,
    ReservationHeader,
    FormFuther,
  },
  data() {
    return {
      message: "",
      cession: null,
      nextKey: 0,
      load: false,
    };
  },
  computed: {
    formFields() {
      return {
        rows: [
          [
            {
              id: { label: "Redni broj", disabled: true, },
            },
            {
              cession_date: {
                type: "date",
                label: "Datum cesije",
                disabled: false,
              },
            },{},{}
            ],[
            {
              dealer: {
                label: "Diler",
                type: "dropdown",
                url: "/customers/type/1?limit=100000&ascending=1&activeOnly=1",
              },
            },
            {
              leasing: {
                label: "Lizing",
                type: "dropdown",
                url: "/customers/type/2?limit=100000&ascending=1&activeOnly=1",
              },
            },
            ],
          [{ divider: { type: "divider" } }],
          [
            {
              exchange_date: {
                label: "Datum kursa",
                type: "date",
                max: new Date().toISOString().substring(0, 10),
              },
            },
            {
              exchange: {
                label: "Valuta",
                type: "dropdown",
                dropdownHeight: "150px",
                list: [
                  { id: "eur", name: "EUR" },
                  { id: "usd", name: "USD" },
                  { id: "aud", name: "AUD" },
                  { id: "chf", name: "CHF" },
                  { id: "gbp", name: "GBP" },
                  { id: "sek", name: "SEK" },
                  { id: "eur_adiko", name: "EUR Adiko" },
                  { id: "usd_adiko", name: "USD Adiko" },
                  { id: "aud_adiko", name: "AUD Adiko" },
                  { id: "chf_adiko", name: "CHF Adiko" },
                  { id: "gbp_adiko", name: "GBP Adiko" },
                  { id: "sek_adiko", name: "SEK Adiko" },
                ],
              },
            },
            // { val_date: { label: "Datum valute", type: "date" } },
            {
              exchange_value: {
                label: "Kurs",
                type: "number",
                description:
                  "Ukoliko kurs nije definisan za izabrani datum, možete ga preuzeti pomoću forme Kursna lista",
                key: "exc" + this.excKey,
              },
            },{}
          ],
          [
            {
              article_id: {
                width: "610px",
                label: "Article",
                type: "dropdown",
                url: "/articles/ext/forDoc/?in_cession=1",
              },
            },
          { bb_neto_eur: { label: "BB neto (EUR)", type: "number" } },
            {
              bb_neto_rsd: {
                label: "BB neto (RSD)",
                type: "number",
                disabled: true,
              },
            },
          ],
          
          [{ divider: { type: "divider" } }],
          [
            { chassis_nr: { label: "Broj šasije", type: "text", description:'Unesite broj šasije i selektujte taster Car Lista da bi preuzeli podatke' } },
            {
              getByCh: {
                type: "button",
                text: " Car Lista",
                emit: "findFromRacChassis",
                biIcon: "bi-car-front",
                btnColor: "btn-warning",
              },
            },
            { registration_nr: { label: "Broj registracije", type: "text" , description:'Unesite broj registracije i selektujte taster Car Lista da bi preuzeli podatke'} },
            {
              getByReg: {
                type: "button",
                text: " Car Lista",
                emit: "findFromRac",
                biIcon: "bi-car-front",
                btnColor: "btn-warning",
              },
            },
          ],
          [
            { brand: { label: "Marka", type: "text" } },
            { model: { label: "Model", type: "text" } },
            { contract_nr: { label: "Broj ugovora", type: "text" } },
            { engine_nr: { label: "Broj motora", type: "text" } },
          ],
          [
            { year: { label: "Godina", type: "number" } },
            { km: { label: "Predjeno KM", type: "number" } },
            { engine_power: { label: "Snaga motora (KW)", type: "number" } },
            {
              engine_volume: { label: "Radna zapremina (ccv)", type: "number" },
            },
          ],
          [
            { seats: { label: "Broj sedista", type: "number" } },
            { color: { label: "Boja", type: "text" } },
            { mass: { label: "Masa (kg)", type: "number" } },
            {
              first_registration: {
                label: "Datum prve registracije",
                type: "date",
              },
            },
          ],
          [{ divider: { type: "divider" } }],
        ],
      };
    },
    resData() {
      return this.cession ?? {};
    },
    // dataCession() {
    //   return {
    //     ...this.data,
    //     bb_neto_rsd:
    //       this.data["cession_invoice"] &&
    //       this.data["cession_invoice"]["exchange"]
    //         ? (this.data["bb_neto_eur"] * this.dataInvoice["exchange"]).toFixed(
    //             2
    //           )
    //         : "0.00",
    //   };
    // },
    requiredFields() {
      return [
        "dealer",
        "leasing",
      ];
    },
  },
  methods: {
    async findFromRac(data) {
      if (!data.registration_nr) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Broj registracije nije validan",
        });
        return;
      }
      CessionsDataService.findFromRac(data.registration_nr)
        .then((response) => {
          if (response.data.length == 0) {
            this.$store.commit("app/showAlert", {
              type: "danger",
              message: "Nije pronađen nijedan rezultat",
            });
            return;
          } 
          this.setDataFromRac(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async findFromRacChassis(data) {
      if (!data.chassis_nr) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Broj šasije nije validan",
        });
        return;
      }
      CessionsDataService.findFromRacByChassis(data.chassis_nr)
        .then((response) => {
          if (response.data.length == 0) {
            this.$store.commit("app/showAlert", {
              type: "danger",
              message: "Nije pronađen nijedan rezultat",
            });
            return;
          } 
          this.setDataFromRac(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setDataFromRac(response) {
      console.log(response, true);
      utils.setDataFromRac(response, this.resData, true);
        console.log('Set neto in rsd');
        this.resData["bb_neto_rsd"] = this.resData["exchange"]
            ? (this.resData["bb_neto_eur"] * this.resData["exchange_value"]).toFixed(2) : "0.00";
    },
    inputChanged(e, name) {
      this.resData[name] = e;

      if (name == "exchange") {
        this.getExchange(this.resData.exchange_date);
      }

      if (name == "exchange_date") {
        this.getExchange(e);
      }

      if (name == "bb_neto_eur") {
        this.resData["bb_neto_rsd"] = this.resData["exchange"]
            ? (this.resData["bb_neto_eur"] * this.resData["exchange_value"]).toFixed(2) : "0.00";
      }
      
    },
    getExchange(date) {
      ExchangeRatesDataService.getByDate(date).then((res) => {
        const exc = res.data[this.resData.exchange];
        this.resData.exchange_value = exc;
        this.resData["bb_neto_rsd"] = this.resData["exchange"]
            ? ((this.resData["bb_neto_eur"] ?? 0) * this.resData["exchange_value"]).toFixed(2) : "0.00";
        // this.nextKey++;
      });
    },
    saveData() {
      // Validate
      if (
        !this.resData.dealer ||
        !this.resData.leasing
      ) {
        let required = [];
        for (const temp of this.requiredFields) {
          if (!this.resData[temp]) required.push(temp);
        }
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: `Molimo vas da unesete sva obavezna polja! ${required}`,
        });
        return;
      }
      if (this.resData && this.resData.id) {
        this.update(this.resData);
      } else {
        this.create(this.resData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      CessionsDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Podaci su promenjeni!",
          });
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      CessionsDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Outbound je kreiran!",
          });
          this.$router.go(-1);
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    deleteData() {
      if (this.resData["invoices"] && this.resData["invoices"].length > 0) {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: "Postoje povezani racuni. Brisanje nije dozvoljeno!",
          });
          return;
      }
      utils.showDialog(
        this.resData,
        "Da li ste sigurni da zelite da obrisete cesiju?",
        "Brisanje cesije: " + this.resData.id,
        () => {
          CessionsDataService.delete(this.resData.id)
            .then((response) => {
              this.$store.commit("app/showAlert", {
                type: "success",
                message: response.data.message,
              });
              this.$store.commit("app/hideModal");

              this.$router.go(-1);
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit("app/showAlert", {
                type: "danger",
                message: e,
              });
            });
        }
      );
    },
  },
  async mounted() {
    this.load = true;
    if (this.$route.params.id != 0) {
      CessionsDataService.get(this.$route.params.id).then(async (res) => {
        this.cession = res.data;
        this.load = false;
      });
    } else {
      this.cession = {
        cession_date: new Date().toISOString().substring(0, 10),
        exchange_date: new Date().toISOString().substring(0, 10),
        exchange: "eur",
        exchange_value: this.getExchange(new Date()),
      }
      this.load = false;
    }
  },
  created() {},
  watch: {},
};
</script>


<style scoped>
.page {
  max-width: 1200px;
  margin-left: 30px;
}
.company {
  font-size: smaller;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>